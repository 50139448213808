import { defineStore } from 'pinia';
import { IResult, UAParser } from 'ua-parser-js';
import { Api } from '@/utils/api.ts';
import { DevicesInfo } from '@/types/Responses/DevicesInfo.ts';
import { DeviceDetailedInfo } from '@/types/Responses/DeviceDetailedInfo.ts';
import { LatestDeviceInfoDetail } from '@/types/Responses/LatestDeviceInfo.ts';
import { UserAgentInfo } from '@/types/Responses/UserAgentInfo.ts';
import { LastestDevicesInfo } from '@/types/Responses/LastestDevicesInfo.ts';

export const useDevicesStore = defineStore('devices', () => {
	const extractUseragentInfo = (ua: string): UserAgentInfo => {
		const mapp = ua.match(
			/^(?<appName>[^/ ]+)\/(?<appVersion>[0-9]+\.[0-9]+\.[0-9]+) (?<osName>[^/ ]+)\/(?<osVersion>[^/ ]+) (?<deviceName>.+)/,
		);

		if (mapp?.groups) {
			const { appName, appVersion, osName, osVersion, deviceName } = mapp.groups;
			return {
				device: deviceName,
				type: 'mobile-screen-button',
				os: `${osName} ${osVersion === 'unknown' ? '' : osVersion}`,
				browser: `${appName} ${appVersion === 'unknown' ? '' : appVersion}`,
			};
		}

		const agent: IResult = UAParser(ua);

		let type = 'laptop';
		switch (agent.device.type) {
			case 'mobile':
				type = 'mobile-screen-button';
				break;
			case 'tablet':
				type = 'tablet-screen-button';
				break;
		}

		let device: string | null = null;

		if (agent.device.vendor) {
			device = agent.device.vendor + ' ' + agent.device.model;
		} else if (agent.browser.name) {
			device = agent.browser.name + ' ' + agent.os.name;
		}

		let browser: string | null = null;

		if (agent.browser.name) {
			browser = agent.browser.name;

			if (agent.browser.version) {
				browser += ' ' + agent.browser.version;
			}
		}

		let os: string | null = null;
		if (agent.os.name) {
			os = agent.os.name;
			if (agent.os.version) {
				os += ' ' + agent.os.version;
			}
		}

		return {
			device,
			type,
			os,
			browser,
		};
	};

	const fetchDevices = async (): Promise<DeviceDetailedInfo[]> => {
		const response = await Api.get(`/user/login/info`);
		const data = await response.json();

		const info: Record<string, DevicesInfo> = data.data;
		const currentLoginId: number | null = data.meta.currentLoginId;

		const ret = [];

		for (const device of Object.values(info)) {
			ret.push({
				...extractUseragentInfo(device.agent),
				id: device.id,
				date: new Date(device.date).toLocaleString(),
				session: device.session,
				sessionDevices: device.sessionDevices.map((session) => {
					return {
						...session,
						...extractUseragentInfo(session.agent),
						date: new Date(session.date).toLocaleString(),
						current: session.id === currentLoginId,
					};
				}),
			});
		}

		return ret;
	};

	const fetchLastDevices = async (): Promise<LatestDeviceInfoDetail[]> => {
		const response = await Api.get(`/user/login/info/latest`);
		const data = await response.json();
		const info: Record<string, LastestDevicesInfo> = data.data;

		const ret = [];

		for (const device of Object.values(info)) {
			ret.push({
				...extractUseragentInfo(device.agent),
				agent: device.agent,
				session: device.session,
				id: device.id,
			});
		}

		return ret;
	};

	return {
		fetchDevices,
		fetchLastDevices,
	};
});
