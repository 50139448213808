import { Header } from '@/types/Header.ts';
import { useAuthStore } from '@/stores/auth.ts';
import { useStorage } from '@vueuse/core';

const fetchDeviceId = (): string => {
	try {
		const v = useStorage('device-id', '');
		if (v.value === '') {
			v.value = crypto.randomUUID();
			localStorage.setItem('device-id', v.value);
		}

		return v.value;
	} catch (e) {
		// For webviews, LocalStorage is NOT enabled
		return '';
	}
};

const commonHeaders: Header = {
	Accept: 'application/json',
	'Content-Type': 'application/json',
	Cache: 'default',
	Authorization: '',
	'X-Device-ID': fetchDeviceId(),
};

const commonUrl: string | undefined = window.location.host.includes('up-luxembourg')
	? import.meta.env.VITE_API_LUX_URL
	: import.meta.env.VITE_API_URL;

const checkResponse = async (response: Response) => {
	if (!response.ok) {
		throw new Error(`HTTP error, status = ${response.status}`);
	}
	return response;
};

const get = async (url: string, params?: any): Promise<Response> => {
	commonHeaders.Authorization = `Bearer ${useAuthStore().token}`;

	const parameters = params ? `?${new URLSearchParams(params).toString()}` : '';
	const response = await fetch(`${commonUrl}${url}${parameters}`, {
		method: 'GET',
		headers: commonHeaders,
	});

	return await checkResponse(response);
};

const post = async (url: string, body: any, fridom?: boolean): Promise<Response> => {
	commonHeaders.Authorization = `Bearer ${useAuthStore().token}`;

	try {
		const response = await fetch(`${fridom ? commonUrl?.replace('account-api', 'api') : commonUrl}${url}`, {
			method: 'POST',
			body: JSON.stringify(body),
			headers: commonHeaders,
		});

		return await checkResponse(response);
	} catch (error) {
		console.error(`Error in POST request: ${error}`);
		throw error;
	}
};

const put = async (url: string, body: any): Promise<Response> => {
	commonHeaders.Authorization = `Bearer ${useAuthStore().token}`;

	try {
		const response = await fetch(`${commonUrl}${url}`, {
			method: 'PUT',
			body: JSON.stringify(body),
			headers: commonHeaders,
		});

		return await checkResponse(response);
	} catch (error) {
		console.error(`Error in PUT request: ${error}`);
		throw error;
	}
};

const deleteRequest = async (url: string, body?: any): Promise<Response> => {
	commonHeaders.Authorization = `Bearer ${useAuthStore().token}`;

	try {
		const response = await fetch(`${commonUrl}${url}`, {
			method: 'DELETE',
			body: JSON.stringify(body),
			headers: commonHeaders,
		});

		return await checkResponse(response);
	} catch (error) {
		console.error(`Error in DELETE request: ${error}`);
		throw error;
	}
};

export const Api = {
	commonHeaders,
	commonUrl,
	get,
	post,
	delete: deleteRequest,
	put,
};
