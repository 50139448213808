<script setup lang="ts">
	import { ref } from 'vue';
	import { MzToast } from '@monizze/monizze-components';
	import { Notification } from '@/types/Notification.ts';

	import type { Ref } from 'vue';

	const show: Ref<boolean> = ref(false);

	const messages: Ref<Array<Notification>> = ref([]);
	const open = (notification: Notification) => {
		messages.value.push(notification);
		show.value = true;
		setTimeout(() => {
			close();
		}, 5000);
	};

	const close = () => {
		if (messages.value.length !== 0) {
			messages.value.shift();
		}

		if (messages.value.length > 0) {
			setTimeout(() => {
				close();
			}, 1000);
		}

		if (messages.value.length === 0) {
			show.value = false;
		}
	};

	defineExpose({
		open,
	});
</script>

<template>
	<transition-group name="nested" tag="div" class="fixed right-10 top-[100px] z-50 w-[300px] overflow-hidden">
		<div v-for="(message, index) in messages" :key="index" class="py-2">
			<MzToast :title="message.title" :description="message.description" :close-action="close" :type="message.type" />
		</div>
	</transition-group>
</template>

<style scoped>
	/* rules that target nested elements */
	.nested-enter-active,
	.nested-leave-active {
		transition: all 0.3s ease-in-out;
	}

	.nested-enter-from,
	.nested-leave-to {
		transform: translateY(-30px);
		opacity: 0;
	}
</style>
