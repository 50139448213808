<script setup lang="ts">
	import { MzButton, MzInput, MzLink } from '@monizze/monizze-components';
	import MzModal from '@/components/MzModal.vue';
	import { useI18n } from 'vue-i18n';
	import { ref, type Ref } from 'vue';
	import { useIntervalFn } from '@vueuse/core';

	const emit = defineEmits<{
		(e: 'close'): void;
		(e: 'edit'): void;
		(e: 'submit'): void;
		(e: 'resend'): void;
	}>();

	const {
		error = false,
		email,
		allowEdit = true,
	} = defineProps<{
		error?: boolean;
		email: string;
		allowEdit?: boolean;
	}>();

	const validationCode = defineModel<string>({ required: true });
	const { t } = useI18n();
	const timeLeft: Ref<number> = ref(15);

	const { pause, resume, isActive } = useIntervalFn(() => {
		if (timeLeft.value > 0) timeLeft.value--;
		else {
			pause();
		}
	}, 1000);

	const resendCode = () => {
		emit('resend');
		timeLeft.value = 30;
		resume();
	};
</script>

<template>
	<mz-modal :title="t('user.mfa.setup.email.verify')" @close="emit('close')">
		<template #content>
			<p>
				{{ t('user.mfa.setup.email.message') }}&nbsp;
				<span class="text-body-intro">{{ email }}</span>
			</p>
			<p>{{ t('user.mfa.setup.phone.code') }}</p>
			<div class="flex flex-row gap-1">
				<p>{{ t('duplicated2fa.page.not_received') }}</p>
				<mz-link
					:label="t('duplicated2fa.page.resend_email')"
					href="#"
					:class="{ 'text-gray-400 cursor-not-allowed': isActive }"
					@click.prevent="!isActive && resendCode()"
				/>
				<p v-if="isActive" class="font-bold">{{ timeLeft }} s</p>
			</div>
			<mz-input
				id="validationCodeEmail"
				v-model="validationCode"
				:label="t('auth.modal.code')"
				:info="error ? t('user.mfa.confirm.code.error') : ''"
				:validated="error ? false : undefined"
				required
				minlength="6"
				class="w-full mt-9"
			/>
		</template>
		<template #footer>
			<a v-if="allowEdit" href="#" class="p-1 text-base mt-2 font-bold" @click.prevent="emit('edit')">
				{{ t('user.mfa.setup.email.edit') }}
			</a>
			<mz-button type="minimal" :label="t('general.cancel')" :width-auto="true" class="pe-10" @click="emit('close')" />
			<mz-button :label="t('user.mfa.setup.send')" :width-auto="true" @click.prevent="emit('submit')" />
		</template>
	</mz-modal>
</template>

<style scoped></style>
