<script setup lang="ts">
	import UserSidebar from '@/components/UserSidebar.vue';
	import MzCard from '@/components/MzCard.vue';
	import { MzButton, MzToast } from '@monizze/monizze-components';
	import { useI18n } from 'vue-i18n';
	import { ref } from 'vue';
	import { Api } from '@/utils/api.ts';
	import MzWrapper from '@/components/MzWrapper.vue';
	import { faTrash } from '@fortawesome/pro-solid-svg-icons';

	type TokenCollectionType = {
		[key: string]: TokenType;
	};

	type TokenType = {
		client_id: number;
		name: string;
		scopes: string[];
	};

	const { t } = useI18n();
	const tokens = ref<TokenType[]>([]);
	const displayToast = ref(false);

	Api.get('/tokens').then((response) => {
		response.json().then((data: TokenCollectionType) => {
			tokens.value = Object.values(data);
		});
	});

	const deleteClientAccess = async (clientId: number) => {
		await Api.delete(`/token/${clientId}`);
		displayToast.value = true;
		tokens.value = tokens.value.filter((token) => token.client_id !== clientId);
	};
</script>

<template>
	<user-sidebar />
	<mz-wrapper :title="t('user.access.title')" :intro="t('user.access.subtitle')">
		<mz-card :title="t('user.access.third_party')">
			<template #content>
				<section class="flex flex-col gap-6 sm:gap-10">
					<div v-for="(token, index) in tokens" :key="index" class="access-row">
						<div>
							<p>{{ token.name }}</p>
							<p>
								{{ token.scopes.map((scope) => t(`auth.scopes.${scope}`)).join(', ') }}
							</p>
						</div>
						<mz-button
							:label="t('user.access.revoke')"
							type="tertiary"
							:icon="faTrash"
							:width-auto="true"
							class="min-w-fit"
							@click="deleteClientAccess(token.client_id)"
						/>
					</div>
				</section>
			</template>
		</mz-card>
	</mz-wrapper>
	<mz-toast
		v-if="displayToast"
		id="client-deleted"
		type="success"
		:title="t('user.access.revoked.title')"
		class="float-right mt-10 mr-10"
		:close-action="() => (displayToast = false)"
	/>
</template>

<style lang="sass" scoped>
	#client-deleted
		@apply fixed top-[100px] right-0

	.access-row
		@apply flex justify-between sm:flex-row flex-col gap-6 items-center
		div
			@apply flex flex-col
			p:first-of-type
				@apply text-global-h6
			p:last-of-type
				@apply text-info-large
</style>
