<script setup lang="ts">
	import { MzSideNavbar, MzTabs } from '@monizze/monizze-components';
	import { faHouse, faKey, faShield, faUser } from '@fortawesome/pro-solid-svg-icons';
	import { useI18n } from 'vue-i18n';

	const { t } = useI18n();
</script>

<template>
	<section>
		<div class="pt-[88px] sm:inline-block hidden">
			<mz-side-navbar
				:keep-open="true"
				:actions="[
					{
						label: t('user.sidebar.dashboard'),
						href: '/user/dashboard',
						icon: faHouse,
						notification: false,
					},
					{
						label: t('user.sidebar.profile'),
						href: '/user/profile',
						icon: faUser,
						notification: false,
					},
					{
						label: t('user.sidebar.security'),
						href: '/user/security',
						icon: faShield,
						notification: false,
					},
					{
						label: t('user.sidebar.access'),
						href: '/user/access',
						icon: faKey,
						notification: false,
					},
				]"
			></mz-side-navbar>
		</div>
		<div class="sm:hidden flex pt-[88px] fixed top-0 left-0 right-0 block whitespace-nowrap bg-neutral-white">
			<mz-tabs
				class="w-auto overflow-x-auto gap-2"
				:tabs="[
					{
						title: t('user.sidebar.dashboard'),
						href: '/user/dashboard',
					},
					{
						title: t('user.sidebar.profile'),
						href: '/user/profile',
					},
					{
						title: t('user.sidebar.security'),
						href: '/user/security',
					},
					{
						title: t('user.sidebar.notifications'),
						href: '/',
					},
					{
						title: t('user.sidebar.access'),
						href: '/',
					},
				]"
			/>
		</div>
	</section>
</template>

<style scoped></style>
