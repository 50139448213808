<script setup lang="ts">
	import UserSidebar from '@/components/UserSidebar.vue';
	import { MzButton, MzPasswordSetup } from '@monizze/monizze-components';
	import { type Ref, ref } from 'vue';
	import MzCard from '@/components/MzCard.vue';
	import { useI18n } from 'vue-i18n';
	import { useRouter } from 'vue-router';
	import MzWrapper from '@/components/MzWrapper.vue';
	import { Api } from '@/utils/api.ts';

	const password: Ref<string> = ref('');
	const validPassword: Ref<boolean> = ref(false);
	const router = useRouter();
	const validatePasswords = (result: boolean): void => {
		validPassword.value = result;
	};
	const { t } = useI18n();

	const updatePassword = async () => {
		if (!validPassword.value) {
			return;
		}

		await Api.put('/user/password', { password: password.value });
		await router.push({ name: 'user-security' });
	};
</script>

<template>
	<user-sidebar />
	<mz-wrapper :title="t('user.password.title')">
		<mz-card :title="t('user.password.title')">
			<template #content>
				<div class="flex flex-col gap-6 text-info-medium">
					<p>{{ t('user.password.description') }}</p>
					<mz-password-setup v-model="password" :inline-mode="false" @passwords-valid="validatePasswords" />
				</div>
			</template>
			<template #footer>
				<div class="flex sm:flex-row sm:gap-10 gap-6 flex-col justify-start">
					<mz-button
						type="primary"
						:label="t('user.password.update')"
						:action="() => updatePassword()"
						:width-auto="true"
						:disabled="!validPassword"
					/>
					<mz-button
						type="minimal"
						:label="t('general.cancel')"
						:action="() => router.push({ name: 'user-security' })"
						:width-auto="true"
					/>
				</div>
			</template>
		</mz-card>
	</mz-wrapper>
</template>

<style scoped></style>
