<script setup lang="ts">
	import { MzButton, MzTag, MzTitle } from '@monizze/monizze-components';
	import MzCard from '@/components/MzCard.vue';
	import { faPowerOff, faCircleCheck, faSquareInfo } from '@fortawesome/pro-solid-svg-icons';
	import { computed } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { LoginDevice } from '@/types/Responses/LoginDevice.ts';

	const { t } = useI18n();

	const { session } = defineProps<{
		session: LoginDevice;
	}>();

	defineEmits<{
		logout: [];
	}>();

	const country = computed(() => {
		if (session.country === null) {
			return t('user.security.device.history.detail.no-country');
		}
		return session.country['en'] ?? t('user.security.device.history.detail.no-country'); // FIXME use translated?
	});

	const city = computed(() => {
		if (session.city === null) {
			return t('user.security.device.history.detail.no-city');
		}
		return session.city['en'] ?? t('user.security.device.history.detail.no-city');
	});
</script>

<template>
	<div>
		<mz-title :text="t('user.security.device.history.detail.title')" />
	</div>
	<mz-card class="mb-9 mt-9">
		<template #content>
			<div class="flex flex-col ms-9 me-9 my-8">
				<div class="border-b-[1px] py-4 lg:text-center">
					<p class="text-body-intro">{{ t('user.security.device.history.detail.connexion') }}</p>
					<p class="text-body">{{ session.date }}</p>
				</div>
				<div class="border-b-[1px] py-4 lg:text-center">
					<p class="text-body-intro">{{ t('user.security.device.history.detail.location') }}</p>
					<p class="text-body">
						{{ city }}
					</p>
					<p class="text-body">{{ country }}</p>
				</div>
				<div class="border-b-[1px] py-4 lg:text-center">
					<p class="text-body-intro">{{ t('user.security.device.history.detail.browser') }}</p>
					<p class="text-body">{{ session.browser }}</p>
				</div>
				<div class="border-b-[1px] py-4 lg:text-center">
					<p class="text-body-intro">{{ t('user.security.device.history.detail.os') }}</p>
					<p class="text-body">{{ session.os }}</p>
				</div>
				<div v-if="session.current || session.active" class="border-b-[1px] py-4 lg:text-center">
					<p class="text-body-intro">{{ t('user.security.device.history.detail.status') }}</p>
					<p class="text-body">
						<mz-tag
							v-if="session.current"
							type="success"
							:label="t('user.security.device.history.detail.current')"
							size="medium"
							:icon="faCircleCheck"
						/>
						<mz-tag
							v-else-if="session.active"
							type="info"
							:label="t('user.security.device.history.detail.active')"
							size="medium"
							:icon="faSquareInfo"
						/>
					</p>
				</div>
				<div class="py-4 w-full">
					<div class="mt-8 items-center w-full">
						<mz-button
							v-if="session.active && !session.current"
							class="self-start w-full"
							type="tertiary"
							:label="t('user.security.device.history.logout')"
							:action="() => $emit('logout')"
							icon-position="left"
							:icon="faPowerOff"
						/>
					</div>
				</div>
			</div>
		</template>
	</mz-card>
</template>

<style scoped></style>
