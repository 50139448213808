<script setup lang="ts">
	import { MzButton } from '@monizze/monizze-components';
	import { faXmark } from '@fortawesome/pro-solid-svg-icons';

	const emit = defineEmits<{
		(e: 'close'): void;
	}>();

	defineProps({
		title: {
			required: false,
			type: String,
			default: '',
		},
	});
</script>

<template>
	<div class="fixed z-50 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
		<div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
			<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
			<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

			<div
				class="inline-block bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 align-middle max-w-lg w-full relative z-50"
			>
				<div class="absolute top-0 right-0 p-4">
					<mz-button type="minimal" :icon="faXmark" @click="emit('close')" />
				</div>
				<div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 z-50">
					<slot name="title" />
					<div class="text-base mt-10">
						<slot name="content" />
					</div>
					<div class="mt-10 flex justify-end gap-10">
						<slot name="footer" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped></style>
