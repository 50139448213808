<script setup lang="ts">
	import { useAuthStore } from '@/stores/auth.ts';
	import { MzButton } from '@monizze/monizze-components';
	import router from '@/router';
	import { useI18n } from 'vue-i18n';

	const authStore = useAuthStore();
	const { t } = useI18n();

	const logout = (): void => {
		authStore.logout();

		router.push('/login/email');
	};
</script>

<template>
	<div v-if="authStore.isLogged">
		<mz-button type="tertiary" :label="t('logout')" class="inline-block" :action="logout" />
	</div>
</template>
