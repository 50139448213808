<script setup lang="ts">
	import { MzNavbar } from '@monizze/monizze-components';
	import Logout from '@/pages/Logout.vue';
	import { useI18n } from 'vue-i18n';
	import { useAuthStore } from '@/stores/auth.ts';
	import { detectedLanguage } from '@/main.ts';
	import { useStorage, useTitle } from '@vueuse/core';
	import { onMounted } from 'vue';
	import { useUserStore } from '@/stores/user.ts';
	import { useRoute } from 'vue-router';

	const getLogo = (): string => {
		return window.location.host.includes('up-luxembourg') ? '/up_luxembourg_logo.svg' : '/monizze_logo.svg';
	};

	const { fallbackLocale, locale, t } = useI18n();
	const authStore = useAuthStore();
	const userStore = useUserStore();

	useTitle(t('head.title'));

	const updateLocale = (newLocale: string): void => {
		locale.value = newLocale;
		if (authStore.isLux) {
			fallbackLocale.value = newLocale.substring(0, 2);
		}
	};

	// Setup default language
	const selectedLanguage = authStore.availableLanguages.includes(detectedLanguage) ? detectedLanguage : authStore.defaultLocale;
	locale.value = selectedLanguage;

	onMounted(() => {
		if (!authStore.token) {
			return;
		}

		try {
			const account = useStorage('account', '{}');
			const parsedData = JSON.parse(account.value);
			if (parsedData && typeof parsedData === 'object' && parsedData.user) {
				userStore.user = parsedData.user;
			} else if (authStore.isLogged) {
				userStore.refreshUser();
			}
		} catch (e) {
			console.info('No local storage detected. Login required');
		}
	});
</script>

<template>
	<mz-navbar
		v-if="useRoute().name !== 'mfa'"
		:current-lang="locale.substring(0, 2)"
		:allowed-lang="authStore.availableLanguages"
		class="w-full lg:px-40 sm:px-12 fixed top-0 sm:shadow-elevation-small shadow-none border-none"
		:logo-url="getLogo()"
		@change-lang="updateLocale"
	>
		<template #login>
			<logout></logout>
		</template>
	</mz-navbar>
	<router-view />
</template>
