<script setup lang="ts">
	import MzModal from '@/components/MzModal.vue';
	import { MzTitle } from '@monizze/monizze-components';
	import { MzButton } from '@monizze/monizze-components';
	import { useI18n } from 'vue-i18n';
	import { DeviceDetailedInfo } from '@/types/Responses/DeviceDetailedInfo.ts';
	import { Api } from '@/utils/api.ts';

	const { t } = useI18n();

	const logout = async () => {
		const id = device?.id || 'null';

		await Api.delete(`/user/login/revoke/${id}`)
			.then(() => {
				emit('close');
				emit('reload');
			})
			.catch(() => {
				emit('error');
			});
	};

	const { show, device } = defineProps<{
		show: boolean;
		device: DeviceDetailedInfo;
	}>();

	const emit = defineEmits<{
		close: [];
		reload: [];
		error: [];
	}>();
</script>

<template>
	<transition>
		<template v-if="show">
			<MzModal class="modal-wrapper-inner" @close="emit('close')">
				<template #title>
					<mz-title
						:text="t('user.security.device.history.modal.title', { device: device.device ?? device.browser + ' ' + device.os })"
						type="h5"
					/>
				</template>
				<template #content>
					<div class="border-b-[1px] flex justify-start pb-8">
						<p class="text-body">{{ t('user.security.device.history.modal.content') }}</p>
					</div>
				</template>
				<template #footer>
					<div class="flex gap-4">
						<mz-button
							type="tertiary"
							:label="t('user.security.device.history.modal.cancel')"
							:action="() => emit('close')"
							:width-auto="true"
						/>
						<mz-button type="primary" :label="t('user.security.device.history.modal.logout')" :action="logout" :width-auto="false" />
					</div>
				</template>
			</MzModal>
		</template>
	</transition>
</template>

<style scoped lang="sass">
	.v-enter-active,
	.v-leave-active
		transition: all 0.5s ease

	.v-enter-from,
	.v-leave-to
		opacity: 0
</style>
